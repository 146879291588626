@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Open Sans", sans-serif;
}

div#wrapper {
  margin: auto;
  padding: 0.5em;
  max-width: 60em;
}

nav {
  text-align: right;
  position: sticky;
  top: 0;
  padding: 1em;
  margin: -1em;
  background: #fff;
}

nav * {
  margin-left: 1em;
}

nav a {
  text-decoration: none;
  color: #000
}

nav a:hover {
  text-decoration: underline;
}

.message {
  padding-left: 0.4em;
  padding-bottom: 0.2em;
  padding-top: 0.4em;
  padding-right: 0.4em;
  margin: 0.2em;
  margin-right: 0;
  border: 1px solid grey;
  border-radius: 4px;
}

a.reply-link {
  color: #666;
  font-size: 0.6em;
  text-decoration: none;
  display: block;
  margin-left: 0.5em;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

div#room {
  max-width: 60em;
  margin: auto;
  padding: 1em;
}

form.message-form {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.message-input {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  font-family: "Open Sans", sans-serif;
}

.uncloak {
  margin-left: 1em;
}


div#create-room-form {
  max-width: 60em;
  margin: auto;
  padding: 2em;
}

div#login-form {
  max-width: 40em;
  margin: auto;
  padding: 2em;
}

.send-login {
  margin-left: 1em;
}

div.email-list {
  font-size: 0.9em;
  display: block;
  white-space: nowrap;
  overflow: auto;
  font-family: monospace;
}

div.visited-list ul {
  padding: 0.5em;
  list-style: none;
}

div.visited-list li {
  margin-bottom: 0.5em;
}

div.visited-list a {
  color: #000;
}

div.byline {
  font-size: 0.6em;
  text-decoration: none;
  padding: 0.2em;
}

div.byline a {
  color: #666;
  text-decoration: none;
}

a.timestamp:hover {
  color: #000;
}

div#about {
  max-width: 40em;
  margin: auto;
}

div#about a {
  color: blue;
  text-decoration: none;
}

div#about a:hover {
  color: blue;
  text-decoration: underline;
}

img.avatar {
  height: 1.1em;
  border-radius: 0.2em;
  padding: 0;
  margin: 0;
  margin-bottom: -0.2em;
  margin-right: 0.5em;
}

div.room-info {
  position: sticky;
  top: 3em;
  margin: -0.5em;
  background: #eee;
  padding-top: 0;
  padding-bottom: 1em;
  padding-left: 1em;
  border-radius: 1em;
  border: 0.5em solid #fff;
}

td.left-form {
  text-align: right;
  padding-right: 1em;
  border-bottom: 1px solid #ccc;
}

.invisible-indent {
  font-size: 0;
}

.chime-box {
  margin-left: 1em;
}

button.save-transcript {
  margin-right: 1em;
}

div.seen-box {
  border: 1px solid #ccc;
  color: #555;
  height: 1.4em;
  width: 1.4em;
  text-align: center;
  border-radius: 0.3em;
  margin-right: -0.2em;
  margin-top: -0.2em;
  margin-left: 1em;
  margin-bottom: 1em;
  float: right;
  font-size: 2em;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
}

div.unseen-box {
  border: 2px solid #333;
  height: 1.4em;
  width: 1.4em;
  text-align: center;
  border-radius: 0.3em;
  margin-right: -0.2em;
  margin-top: -0.2em;
  margin-left: 1em;
  margin-bottom: 1em;
  float: right;
  font-size: 2em;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
}

